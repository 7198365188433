<template>
  <v-footer app color="transparent" padless>
    <v-bottom-navigation color="primary" fixed dark mandatory background-color="#232126" grow app class="rounded-t-xl" height="64">
      <v-btn to="/s/home" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Home')}}</span>
        <iconly name="home" type="light" size="2x" />
      </v-btn>

      <v-btn to="/s/explore" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Explore')}}</span>
        <iconly name="category" type="light" size="2x" />
      </v-btn>

      <v-btn to="/s/contents/create" class="text-decoration-none">
        <iconly name="plus" type="bold" size="3x" class="pointer primary--text"/>
      </v-btn>

      <v-btn :to="sp" :href="sp" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('My page')}}</span>
        <iconly name="document" type="light" size="2x" />
      </v-btn>

      <v-btn to="/s/profile" class="text-decoration-none">
        <span class="mt-1 f10">{{$t('Profile')}}</span>
        <iconly name="profile" type="light" size="2x" />
      </v-btn>
    </v-bottom-navigation>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      sp: "/s/specialist/" + this.$store.getters.doctorId,
    }
  },
  methods: {

  },
};
</script>
