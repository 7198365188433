<template>
  <section>
    <Header />
    <v-main class="my-2" id="basic-main">
      <v-container fluid>
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>
    <Footer />
  </section>
</template>
<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "Container",
  components: {
    Header,
    Footer,
  },
};
</script>
<style scoped></style>
